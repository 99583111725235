import React from "react";
import { HeadFC, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const Privacy = () => {
  const { t } = useI18next();
  const contents = t<string, string[]>("contents", { returnObjects: true });

  const title = t("privacyTitle");

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
      </Helmet>
      <Layout>
        <main className="mx-auto px-25 py-100 md:w-1160 md:px-0">
          <h1 className="text-24 font-medium leading-[1.8]">{t("title")}</h1>
          <time className="mt-10 block text-16 font-medium leading-[1.8]">{t("date")}</time>
          <p className="mt-20 whitespace-pre-wrap text-14 leading-[1.8]">{t("description")}</p>
          {contents.map((content, i) => (
            <div key={i} className="terms-block" dangerouslySetInnerHTML={{ __html: content }} />
          ))}
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query PrivacyPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["privacy", "meta"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Privacy;

export const Head: HeadFC = () => <title>Not found</title>;
